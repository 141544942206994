import { Axios } from "axios";
import { appendAuthenticationHeader } from "../RestClientService";

const API = {
  listCompanies: {
    url: () => {
      return `/companies/list`;
    },
    method: "GET",
  },
  activateCompany: {
    url: (id: string) => {
      return `/companies/${id}/activate`;
    },
    method: "PATCH",
  },
  deactivateCompany: {
    url: (id: string) => {
      return `/companies/${id}/deactivate`;
    },
    method: "PATCH",
  },
};

export class CompanyService {
  constructor(private axios: Axios) {}

  async listCompanies(authToken: string, jwtToken: string) {
    const response = this.axios.request({
      url: API.listCompanies.url(),
      method: API.listCompanies.method,
      headers: appendAuthenticationHeader(
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        authToken
      ),
    });
    return response;
  }

  async activateCompany(
    authToken: string,
    jwtToken: string,
    customerId: string
  ) {
    const response = this.axios.request({
      url: API.activateCompany.url(customerId),
      method: API.activateCompany.method,
      headers: appendAuthenticationHeader(
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        authToken
      ),
    });
    return response;
  }

  async deactivateCompany(
    authToken: string,
    jwtToken: string,
    customerId: string
  ) {
    const response = this.axios.request({
      url: API.deactivateCompany.url(customerId),
      method: API.deactivateCompany.method,
      headers: appendAuthenticationHeader(
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        authToken
      ),
    });
    return response;
  }
}
