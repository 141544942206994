import { Box, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import HeaderSidebarLayout from "../../components/Layout/HeaderSidebarLayout";
import { useAuth } from "../../contexts/AuthContext";
import { services } from "../../services/services";
import { ICustomer, ICustomerListResponse } from "../../types/CustomerTypes";

const CustomersView: React.FC = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.customerService
        .listCustomers(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const customerListResponse: ICustomerListResponse = response?.data;
            setCustomers(customerListResponse.customers);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const tableColumns: GridColDef<(typeof customers)[number]>[] = [
    { field: "id", headerName: "#ID", width: 50, type: "number" },
    {
      field: "nmCustomer",
      headerName: "Cliente",
      width: 250,
      type: "string",
    },
    {
      field: "nuCpf",
      headerName: "CPF",
      width: 150,
      type: "string",
      sortable: false,
    },
    {
      field: "nuPhone",
      headerName: "Celular",
      width: 125,
      type: "string",
      sortable: false,
    },
    {
      field: "enEmail",
      headerName: "E-mail",
      width: 125,
      type: "string",
      sortable: false,
    },
    {
      field: "dtCreated",
      headerName: "Criado em",
      width: 135,
      type: "string",
      sortable: false,
    },
    {
      field: "flActive",
      headerName: "Ativa?",
      width: 75,
      type: "boolean",
      sortable: false,
    },
    {
      field: "flDeleted",
      headerName: "Excluída?",
      width: 85,
      type: "boolean",
      sortable: false,
    },
  ];

  return (
    <HeaderSidebarLayout>
      <Breadcrumbs labels={["Clientes"]} />
      <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
        <Box sx={{ minHeight: 600, width: "100%", p: 2 }}>
          <DataGrid
            columns={tableColumns}
            rows={customers}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            autoHeight={true}
            rowSelection={false}
            loading={isLoading}
          />
        </Box>
      </Paper>
    </HeaderSidebarLayout>
  );
};

export default CustomersView;
