import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { services } from "../../../../services/services";
import { FormatNumberToBRL } from "../../../../services/utils/numberFormater";
import { ICardTotalResponse } from "../../../../types/DashboardTypes";
import TotalCard from "../../TotalCard";

interface PurchaseAverageCardProps {
  title: string;
  subtitle?: string;
}

const PurchaseAverageCard: React.FC<PurchaseAverageCardProps> = ({
  title,
  subtitle,
}) => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [amount, setAmount] = useState<number>(0);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.dashboardService
        .getAmountPurchaseAverage(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const _reponse: ICardTotalResponse = response?.data;
            setAmount(_reponse.value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TotalCard
      title={title}
      subtitle={subtitle}
      value={FormatNumberToBRL(amount)}
      loading={isLoading}
    />
  );
};

export default PurchaseAverageCard;
