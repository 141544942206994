import { Axios } from "axios";
import { appendAuthenticationHeader } from "../RestClientService";

const API = {
  listVouchers: {
    url: () => {
      return `/vouchers/list`;
    },
    method: "GET",
  },
  listVouchersCredits: {
    url: () => {
      return `/voucherCredits/list`;
    },
    method: "GET",
  },
};

export class VoucherService {
  constructor(private axios: Axios) {}

  async listVouchers(authToken: string, jwtToken: string) {
    const response = this.axios.request({
      url: API.listVouchers.url(),
      method: API.listVouchers.method,
      headers: appendAuthenticationHeader(
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        authToken
      ),
    });
    return response;
  }

  async listVouchersCredits(authToken: string, jwtToken: string) {
    const response = this.axios.request({
      url: API.listVouchersCredits.url(),
      method: API.listVouchersCredits.method,
      headers: appendAuthenticationHeader(
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        authToken
      ),
    });
    return response;
  }
}
