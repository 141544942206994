import { Container, Grid } from "@mui/material";
import * as React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import HeaderSidebarLayout from "../../components/Layout/HeaderSidebarLayout";

const LastRecordsView: React.FC = () => {
  return (
    <HeaderSidebarLayout>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Breadcrumbs labels={["Relatórios", "Últimos registros"]} />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={2}></Grid>
        </Container>
      </Container>
    </HeaderSidebarLayout>
  );
};

export default LastRecordsView;
