export const FormatNumberToBRL = (value?: number): string => {
  if (value === undefined) {
    return "";
  }

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};
