import { Axios } from "axios";
import { getHeaders } from "../RestClientService";

const API = {
  getAmountPurchaseTotal: {
    url: () => {
      return `/dashboard/amounts/purchaseTotal`;
    },
    method: "GET",
  },
  getAmountPurchaseAverage: {
    url: () => {
      return `/dashboard/amounts/purchaseAverage`;
    },
    method: "GET",
  },
  getAmountTaxTotal: {
    url: () => {
      return `/dashboard/amounts/taxTotal`;
    },
    method: "GET",
  },
  getChartColumnVouchersRedeemed: {
    url: () => {
      return `/dashboard/charts/column/vouchersRedeemed`;
    },
    method: "GET",
  },
  getChartColumnVouchersUsed: {
    url: () => {
      return `/dashboard/charts/column/vouchersUsed`;
    },
    method: "GET",
  },
  getChartColumnPurchasesQuantity: {
    url: () => {
      return `/dashboard/charts/column/purchasesQuantity`;
    },
    method: "GET",
  },
  getChartColumnPurchasesValue: {
    url: () => {
      return `/dashboard/charts/column/purchasesValue`;
    },
    method: "GET",
  },
};

export class DashboardService {
  constructor(private axios: Axios) {}

  async getAmountPurchaseTotal(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.getAmountPurchaseTotal.url(),
      method: API.getAmountPurchaseTotal.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async getAmountPurchaseAverage(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.getAmountPurchaseAverage.url(),
      method: API.getAmountPurchaseAverage.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async getAmountTaxTotal(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.getAmountTaxTotal.url(),
      method: API.getAmountTaxTotal.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async getChartColumnVouchersRedeemed(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.getChartColumnVouchersRedeemed.url(),
      method: API.getChartColumnVouchersRedeemed.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async getChartColumnVouchersUsed(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.getChartColumnVouchersUsed.url(),
      method: API.getChartColumnVouchersUsed.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async getChartColumnPurchasesQuantity(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.getChartColumnPurchasesQuantity.url(),
      method: API.getChartColumnPurchasesQuantity.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async getChartColumnPurchasesValue(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.getChartColumnPurchasesValue.url(),
      method: API.getChartColumnPurchasesValue.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }
}
