import { Box, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import HeaderSidebarLayout from "../../components/Layout/HeaderSidebarLayout";
import { useAuth } from "../../contexts/AuthContext";
import { services } from "../../services/services";
import { FormatNumberToBRL } from "../../services/utils/numberFormater";
import {
  ICompanyFinance,
  ICompanyFinanceListResponse,
} from "../../types/ReportTypes";

const CompaniesFinanceView: React.FC = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [companies, setCompanies] = useState<ICompanyFinance[]>([]);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.reportService
        .listCompaniesFinance(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const _response: ICompanyFinanceListResponse = response?.data;
            setCompanies(_response?.companies || []);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const tableColumns: GridColDef<(typeof companies)[number]>[] = [
    { field: "id", headerName: "#ID", width: 50, type: "number" },
    {
      field: "nmCompany",
      headerName: "Empresa",
      width: 250,
      type: "string",
    },
    {
      field: "nuCnpj",
      headerName: "CNPJ",
      width: 150,
      type: "string",
      sortable: false,
    },
    {
      field: "vlBalance",
      headerName: "Saldo",
      width: 125,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },
    {
      field: "vlPurchase",
      headerName: "Compras",
      width: 125,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },

    {
      field: "vlCashbackGenerated",
      headerName: "Cashback gerado",
      width: 125,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },

    {
      field: "vlVdcTax",
      headerName: "Taxa VDC",
      width: 125,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },

    {
      field: "vlCashbackPaid",
      headerName: "Cashback à Pagar",
      width: 150,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },

    {
      field: "vlCashbackReceived",
      headerName: "Recebimento em Cashback",
      width: 200,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },
  ];

  const autosizeOptions = {
    columns: [
      "id",
      "vlBalance",
      "vlPurchase",
      "vlCashbackGenerated",
      "vlVdcTax",
      "vlCashbackPaid",
      "vlCashbackReceived",
    ],
    includeOutliers: true,
    includeHeaders: true,
  };

  return (
    <HeaderSidebarLayout>
      <Breadcrumbs labels={["Relatórios", "Empresas", "Financeiro"]} />
      <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
        <Box sx={{ minHeight: 600, width: "100%", p: 2 }}>
          <DataGrid
            columns={tableColumns}
            rows={companies}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            autoHeight={true}
            rowSelection={false}
            autosizeOptions={autosizeOptions}
            loading={isLoading}
          />
        </Box>
      </Paper>
    </HeaderSidebarLayout>
  );
};

export default CompaniesFinanceView;
