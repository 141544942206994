import React, { ReactNode, createContext, useContext, useState } from "react";

interface AuthContextProps {
  userId: string;
  userToken: string;
  isAuthenticated: boolean;
  storeAuthResponse: (id: string, token: string) => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userId, setUserId] = useState<string>("");
  const [userToken, setUserToken] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const storeAuthResponse = (id: string, token: string): void => {
    setUserId(id);
    setUserToken(token);
    setIsAuthenticated(true);
  };

  const signOut = () => {
    setUserId("");
    setUserToken("");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ userId, userToken, isAuthenticated, storeAuthResponse, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
