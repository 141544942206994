import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import DashboardView from "./views/DashboardView";
import LoginView from "./views/auth/LoginView";
import CompaniesView from "./views/records/CompaniesView";
import CustomersView from "./views/records/CustomersView";
import PurchasesView from "./views/records/PurchasesView";
import CompanyTransactionsView from "./views/records/transactions/CompanyTransactionsView";
import CustomerTransactionsView from "./views/records/transactions/CustomerTransactionsView";
import VoucherCreditsView from "./views/records/vouchers/VoucherCreditsView";
import VouchersView from "./views/records/vouchers/VouchersView";
import CompaniesFinanceView from "./views/reports/CompaniesFinanceView";
import LastRecordsView from "./views/reports/LastRecordsView";
import ReferralsView from "./views/reports/ReferralsView";

const AppRoutes: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/" element={<LoginView />} />
      <Route
        path="/dashboard"
        element={<ProtectedRoute component={DashboardView} />}
      />
      <Route
        path="/companies"
        element={<ProtectedRoute component={CompaniesView} />}
      />
      <Route
        path="/customers"
        element={<ProtectedRoute component={CustomersView} />}
      />
      <Route
        path="/vouchers"
        element={<ProtectedRoute component={VouchersView} />}
      />
      <Route
        path="/vouchers/credits"
        element={<ProtectedRoute component={VoucherCreditsView} />}
      />
      <Route
        path="/purchases"
        element={<ProtectedRoute component={PurchasesView} />}
      />
      <Route
        path="/transactions/customer"
        element={<ProtectedRoute component={CustomerTransactionsView} />}
      />
      <Route
        path="/transactions/company"
        element={<ProtectedRoute component={CompanyTransactionsView} />}
      />
      <Route
        path="/reports/companies/finance"
        element={<ProtectedRoute component={CompaniesFinanceView} />}
      />
      <Route
        path="/reports/referrals"
        element={<ProtectedRoute component={ReferralsView} />}
      />
      <Route
        path="/reports/lastRecords"
        element={<ProtectedRoute component={LastRecordsView} />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </Router>
);

export default AppRoutes;
