import { RestClientService } from "./RestClientService";
import { AuthService } from "./web/AuthService";
import { CompanyService } from "./web/CompanyService";
import { CustomerService } from "./web/CustomerService";
import { DashboardService } from "./web/DashboardService";
import { PurchaseService } from "./web/PurchaseService";
import { ReferralService } from "./web/ReferralService";
import { ReportService } from "./web/ReportService";
import { TransactionService } from "./web/TransactionService";
import { VoucherService } from "./web/VoucherService";

const restClientService = new RestClientService();
const axiosInstance = restClientService.axiosInstance();

const authService = new AuthService(axiosInstance);
const customerService = new CustomerService(axiosInstance);
const companyService = new CompanyService(axiosInstance);
const voucherService = new VoucherService(axiosInstance);
const purchaseService = new PurchaseService(axiosInstance);
const transactionService = new TransactionService(axiosInstance);
const dashboardService = new DashboardService(axiosInstance);
const reportService = new ReportService(axiosInstance);
const referralService = new ReferralService(axiosInstance);

export const services = {
  axios: axiosInstance,
  authService,
  customerService,
  companyService,
  voucherService,
  purchaseService,
  transactionService,
  dashboardService,
  reportService,
  referralService,
} as const;

export type IServices = typeof services;
