import { Axios } from "axios";
import { getHeaders } from "../RestClientService";

const API = {
  listReferral: {
    url: () => {
      return `/referrals/list`;
    },
    method: "GET",
  },
};

export class ReferralService {
  constructor(private axios: Axios) {}

  async listReferral(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.listReferral.url(),
      method: API.listReferral.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }
}
