import { Box, Paper, Switch } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import HeaderSidebarLayout from "../../components/Layout/HeaderSidebarLayout";
import { useAuth } from "../../contexts/AuthContext";
import { COMPANY_SEGMENTS } from "../../data/segments";
import { services } from "../../services/services";
import { IDefaultResponse } from "../../types/CommonsTypes";
import { ICompany, ICompanyListResponse } from "../../types/CompanyTypes";

const CompaniesView: React.FC = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.companyService
        .listCompanies(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const companyListResponse: ICompanyListResponse = response?.data;
            setCompanies(companyListResponse.companies);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function getCompanyAddress(company: ICompany) {
    return `${company.dsAddress}, ${company.nuAddress}${
      company.dsComplement ? " - " + company.dsComplement : ""
    },\n${company.nmDistrict}, ${company.nmCity}, ${company.nmState},\n${
      company.nuZipcode
    }`;
  }

  function getCompanyOwner(company: ICompany) {
    return `${company.nmOwner}\nTel: ${company.nuPhone}\nE-mail: ${company.enEmail}`;
  }

  function getSegmentName(cdSegment: number): string {
    return (
      COMPANY_SEGMENTS.find((s) => s.value === cdSegment.toString())?.label ??
      ""
    );
  }

  const updateStatus = async (companyId: string, active: boolean) => {
    if (!isLoading) {
      try {
        setLoading(true);
        if (active) {
          await services.companyService
            .activateCompany(userId, userToken, companyId)
            .then((response) => {
              if (response.status === 200) {
                const _resp: IDefaultResponse = response?.data;
                if (_resp.success) {
                  loadData();
                } else {
                  console.log("Erro ao atualizar");
                  setLoading(false);
                }
              }
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        } else {
          await services.companyService
            .deactivateCompany(userId, userToken, companyId)
            .then((response) => {
              if (response.status === 200) {
                const _resp: IDefaultResponse = response?.data;
                if (_resp.success) {
                  loadData();
                } else {
                  console.log("Erro ao atualizar");
                  setLoading(false);
                }
              }
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const tableColumns: GridColDef<(typeof companies)[number]>[] = [
    { field: "id", headerName: "#ID", width: 50, type: "number" },
    {
      field: "nmCompany",
      headerName: "Empresa",
      width: 250,
      type: "string",
    },
    {
      field: "nuCnpj",
      headerName: "CNPJ",
      width: 150,
      type: "string",
      sortable: false,
    },
    {
      field: "segment",
      headerName: "Segmento",
      width: 175,
      valueGetter: (value, row) => getSegmentName(row.cdSegment),
      sortable: true,
    },
    {
      field: "address",
      headerName: "Endereço",
      width: 150,
      valueGetter: (value, row) => getCompanyAddress(row),
      sortable: false,
    },
    {
      field: "owner",
      headerName: "Responsável",
      width: 165,
      valueGetter: (value, row) => getCompanyOwner(row),
      sortable: false,
    },
    {
      field: "dtCreated",
      headerName: "Criado em",
      width: 135,
      type: "string",
      sortable: false,
    },
    {
      field: "flActive",
      headerName: "Ativa?",
      width: 75,
      type: "boolean",
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, boolean>) => (
        <div>
          <Switch
            onChange={(event) =>
              updateStatus(params.rowNode.id?.toString(), event.target.checked)
            }
            checked={params.value}
          />
        </div>
      ),
    },
    {
      field: "flDeleted",
      headerName: "Excluída?",
      width: 85,
      type: "boolean",
      sortable: false,
    },
  ];

  return (
    <HeaderSidebarLayout>
      <Breadcrumbs labels={["Empresas"]} />
      <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
        <Box sx={{ minHeight: 600, width: "100%", p: 2 }}>
          <DataGrid
            columns={tableColumns}
            rows={companies}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            autoHeight={true}
            rowSelection={false}
            loading={isLoading}
          />
        </Box>
      </Paper>
    </HeaderSidebarLayout>
  );
};

export default CompaniesView;
