import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import ReactEcharts from "echarts-for-react";

interface ColumnChartCardProps {
  title: string;
  subtitle?: string;
  chartOptions: any;
  loading: boolean;
}

const ColumnChartCard: React.FC<ColumnChartCardProps> = ({
  title,
  subtitle,
  chartOptions,
  loading,
}) => {
  return (
    <Paper
      sx={{
        p: 0,
        display: "flex",
        flexDirection: "column",
        height: 300,
      }}
    >
      {loading && (
        <Box
          sx={{
            flex: 1,
            flexDirection: "column",
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && chartOptions && (
        <>
          <Box
            sx={{
              pt: 2,
              pl: 4,
              flexDirection: "column",
              textAlign: "start",
            }}
          >
            <Typography
              variant="body1"
              color="primary"
              sx={{ fontSize: "1rem", fontWeight: 800 }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant="body2"
                color="secondary"
                sx={{ fontSize: "0.75rem", fontWeight: 600 }}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
          <ReactEcharts option={chartOptions} />
        </>
      )}
    </Paper>
  );
};

export default ColumnChartCard;
