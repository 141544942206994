// export const FALLBACK_SERVER_ENVIRONMENT = "localhost";
export const FALLBACK_SERVER_ENVIRONMENT = "development";

export const SERVER_ENVIRONMENT: IServerEnvironment =
  (process.env.SERVER_ENVIRONMENT as IServerEnvironment) ||
  FALLBACK_SERVER_ENVIRONMENT;

export const IS_LOCAL = SERVER_ENVIRONMENT === "localhost";

export type IServerEnvironment = "localhost" | "development" | "production";
