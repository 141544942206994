import axios from "axios";
import { getServerConfig } from "../configs/ServerConfig";

const ONE_SECOND = 1000;

export class RestClientService {
  axiosInstance() {
    const config = getServerConfig();

    return axios.create({
      baseURL: config.serviceApiUrl,
      timeout: 30 * ONE_SECOND,
    });
  }
}

export function appendAuthenticationHeader(
  headers: Record<string, string>,
  authToken: string
): Record<string, string> {
  return { ...headers, "X-Auth-UserToken": authToken };
}

export function getHeaders(
  authToken: string,
  jwtToken: string
): Record<string, string> {
  return {
    "Content-Type": "application/json",
    "X-Auth-UserToken": authToken,
    Authorization: `Bearer ${jwtToken}`,
  };
}
