import { Box, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import HeaderSidebarLayout from "../../components/Layout/HeaderSidebarLayout";
import { useAuth } from "../../contexts/AuthContext";
import { services } from "../../services/services";
import { IReferral, IReferralListResponse } from "../../types/ReferralTypes";

const ReferralsView: React.FC = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [referrals, setReferrals] = useState<IReferral[]>([]);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.referralService
        .listReferral(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const _response: IReferralListResponse = response?.data;
            setReferrals(_response?.referrals || []);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const tableColumns: GridColDef<(typeof referrals)[number]>[] = [
    {
      field: "nmReferrer",
      headerName: "Indicador",
      width: 250,
      type: "string",
    },
    {
      field: "nmReferrerType",
      headerName: "Indicador: Tipo",
      width: 125,
      valueGetter: (value, row) =>
        value === "CUSTOMER" ? "Cliente" : "Empresa",
      sortable: false,
    },
    {
      field: "nmReferred",
      headerName: "Indicado",
      width: 250,
      type: "string",
    },
    {
      field: "nmReferredType",
      headerName: "Tipo Indicado",
      width: 125,
      valueGetter: (value, row) =>
        value === "CUSTOMER" ? "Cliente" : "Empresa",
      sortable: false,
    },
    {
      field: "dtCreated",
      headerName: "Criado em",
      width: 150,
      type: "string",
      sortable: false,
    },
  ];

  return (
    <HeaderSidebarLayout>
      <Breadcrumbs labels={["Relatórios", "Indicações"]} />
      <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
        <Box sx={{ minHeight: 600, width: "100%", p: 2 }}>
          <DataGrid
            columns={tableColumns}
            rows={referrals}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            autoHeight={true}
            rowSelection={false}
            loading={isLoading}
          />
        </Box>
      </Paper>
    </HeaderSidebarLayout>
  );
};

export default ReferralsView;
