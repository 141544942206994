import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { services } from "../../../../services/services";
import { FormatNumberToBRL } from "../../../../services/utils/numberFormater";
import { ICardTotalResponse } from "../../../../types/DashboardTypes";
import TotalCard from "../../TotalCard";

interface TaxTotalCardProps {
  title: string;
  subtitle?: string;
}

const TaxTotalCard: React.FC<TaxTotalCardProps> = ({ title, subtitle }) => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [amount, setAmount] = useState(0);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.dashboardService
        .getAmountTaxTotal(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const _reponse: ICardTotalResponse = response?.data;
            setAmount(_reponse.value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TotalCard
      title={title}
      subtitle={subtitle}
      value={FormatNumberToBRL(amount)}
      loading={isLoading}
    />
  );
};

export default TaxTotalCard;
