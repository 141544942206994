import { Axios } from "axios";
import { getHeaders } from "../RestClientService";

const API = {
  listCompaniesFinance: {
    url: () => {
      return `/reports/companies/finance`;
    },
    method: "GET",
  },
  listLastVouchersRedeemed: {
    url: () => {
      return `/reports/lists/vouchers/redeemed`;
    },
    method: "GET",
  },
  listLastVouchersUsed: {
    url: () => {
      return `/reports/lists/vouchers/used`;
    },
    method: "GET",
  },
  listLastVouchersAccepted: {
    url: () => {
      return `/reports/lists/vouchers/accepted`;
    },
    method: "GET",
  },
  listLastPurchases: {
    url: () => {
      return `/reports/lists/purchases`;
    },
    method: "GET",
  },
};

export class ReportService {
  constructor(private axios: Axios) {}

  async listCompaniesFinance(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.listCompaniesFinance.url(),
      method: API.listCompaniesFinance.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async listLastVouchersRedeemed(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.listLastVouchersRedeemed.url(),
      method: API.listLastVouchersRedeemed.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async listLastVouchersUsed(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.listLastVouchersUsed.url(),
      method: API.listLastVouchersUsed.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async listLastVouchersAccepted(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.listLastVouchersAccepted.url(),
      method: API.listLastVouchersAccepted.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }

  async listLastPurchases(authToken: string, jwtToken: string) {
    return this.axios.request({
      url: API.listLastPurchases.url(),
      method: API.listLastPurchases.method,
      headers: getHeaders(authToken, jwtToken),
    });
  }
}
