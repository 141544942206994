import { Box, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import HeaderSidebarLayout from "../../../components/Layout/HeaderSidebarLayout";
import { useAuth } from "../../../contexts/AuthContext";
import { COMPANY_SEGMENTS } from "../../../data/segments";
import { services } from "../../../services/services";
import { IVoucher, IVoucherListResponse } from "../../../types/VoucherTypes";

const VouchersView: React.FC = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [vouchers, setVouchers] = useState<IVoucher[]>([]);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.voucherService
        .listVouchers(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const voucherListResponse: IVoucherListResponse = response?.data;
            setVouchers(voucherListResponse.vouchers);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function getSegmentName(cdSegment: number): string {
    return (
      COMPANY_SEGMENTS.find((s) => s.value === cdSegment.toString())?.label ??
      ""
    );
  }

  const tableColumns: GridColDef<(typeof vouchers)[number]>[] = [
    { field: "id", headerName: "#ID", width: 50, type: "number" },
    {
      field: "nmCompany",
      headerName: "Empresa",
      width: 100,
      type: "string",
    },
    {
      field: "nuCnpj",
      headerName: "CNPJ",
      width: 100,
      type: "string",
      sortable: false,
    },
    {
      field: "dsVoucher",
      headerName: "Voucher",
      width: 100,
      type: "string",
      sortable: false,
    },
    {
      field: "dsInfo",
      headerName: "Informações",
      width: 100,
      type: "string",
      sortable: false,
    },
    {
      field: "cdSegment",
      headerName: "Segmento",
      width: 100,
      valueGetter: (value, row) => getSegmentName(row.cdSegment),
      sortable: false,
    },
    {
      field: "vlDiscount",
      headerName: "Cashback",
      width: 100,
      valueGetter: (value, row) => `${value}%`,
      sortable: false,
    },
    {
      field: "qtVoucher",
      headerName: "Publicados",
      width: 100,
      type: "number",
      sortable: false,
    },
    {
      field: "qtUsed",
      headerName: "Efetivados",
      width: 100,
      type: "number",
      sortable: false,
    },
    {
      field: "dtCreated",
      headerName: "Criado em",
      width: 100,
      type: "string",
      sortable: false,
    },
    {
      field: "flActive",
      headerName: "Ativo?",
      width: 100,
      type: "boolean",
      sortable: false,
    },
  ];

  return (
    <HeaderSidebarLayout>
      <Breadcrumbs labels={["Vouchers", "Cadastros"]} />
      <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
        <Box sx={{ minHeight: 600, width: "100%", p: 2 }}>
          <DataGrid
            columns={tableColumns}
            rows={vouchers}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            autoHeight={true}
            rowSelection={false}
            loading={isLoading}
          />
        </Box>
      </Paper>
    </HeaderSidebarLayout>
  );
};

export default VouchersView;
