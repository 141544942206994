import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../../contexts/AuthContext";
import { services } from "../../../../../services/services";
import { IChartColumnResponse } from "../../../../../types/DashboardTypes";
import ColumnChartCard from "../../../ColumnChartCard";

interface PurchasesQuantityColumnChartProps {
  title: string;
  subtitle?: string;
}

const PurchasesQuantityColumnChart: React.FC<
  PurchasesQuantityColumnChartProps
> = ({ title, subtitle }) => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [chartOptions, setChartOptions] = useState<any>();
  const loadData = async () => {
    try {
      setLoading(true);
      await services.dashboardService
        .getChartColumnPurchasesQuantity(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const _reponse: IChartColumnResponse = response?.data;
            setChartOptions({
              xAxis: {
                type: "category",
                data: _reponse.labels,
              },
              yAxis: {
                type: "value",
              },
              series: [
                {
                  data: _reponse.values,
                  type: "bar",
                },
              ],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ColumnChartCard
      title={title}
      subtitle={subtitle}
      chartOptions={chartOptions}
      loading={isLoading}
    />
  );
};

export default PurchasesQuantityColumnChart;
