import { Box, Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";

interface BreadcrumbsProps {
  labels: string[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ labels }) => {
  return (
    <Box>
      <MUIBreadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" variant="body1" align="left">
          Admin
        </Typography>
        {labels.map((label, idx) => (
          <Typography
            key={`${idx}${label}`}
            color="text.primary"
            variant="body1"
            align="left"
          >
            {label}
          </Typography>
        ))}
      </MUIBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
