import {
  IS_LOCAL,
  IServerEnvironment,
  SERVER_ENVIRONMENT,
} from "./Environment";

export function getServerConfig() {
  return {
    isLocal: IS_LOCAL,
    serviceApiUrl: `${SERVICE_API[SERVER_ENVIRONMENT]}/${SERVICE_API_CONTEXT}`,
  };
}

const SERVICE_API_CONTEXT = "web";

const SERVICE_API: Record<IServerEnvironment, string> = {
  // localhost: "http://192.168.15.64:8088",
  localhost: "https://api.viladocashback.app",
  development: "https://api.viladocashback.app",
  production: "https://api.viladocashback.app",
};
