import { Box, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import HeaderSidebarLayout from "../../components/Layout/HeaderSidebarLayout";
import { useAuth } from "../../contexts/AuthContext";
import { services } from "../../services/services";
import { FormatNumberToBRL } from "../../services/utils/numberFormater";
import { IPurchase, IPurchaseListResponse } from "../../types/PurchaseTypes";

const PurchasesView: React.FC = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [purchases, setPurchases] = useState<IPurchase[]>([]);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.purchaseService
        .listPurchases(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const purchaseListResponse: IPurchaseListResponse = response?.data;
            setPurchases(purchaseListResponse.purchases);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const tableColumns: GridColDef<(typeof purchases)[number]>[] = [
    { field: "id", headerName: "#ID", width: 50, type: "number" },
    {
      field: "nmCustomer",
      headerName: "Cliente",
      width: 150,
      type: "string",
    },
    {
      field: "nmCompany",
      headerName: "Empresa",
      width: 150,
      type: "string",
      sortable: false,
    },
    {
      field: "dsVoucher",
      headerName: "Voucher",
      width: 150,
      type: "string",
      sortable: false,
    },
    {
      field: "dtPurchase",
      headerName: "Data da Compra",
      width: 125,
      type: "string",
      sortable: false,
    },
    {
      field: "vlPurchase",
      headerName: "Valor da Compra",
      width: 125,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
      sortable: false,
    },
    {
      field: "vlCashbackSpent",
      headerName: "Cashback utilizado",
      width: 125,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
      sortable: false,
    },
    {
      field: "vlCashbackGenerated",
      headerName: "Cashback gerado",
      width: 125,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
      sortable: false,
    },
    {
      field: "vlVdcTax",
      headerName: "Taxa VDC",
      width: 125,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
      sortable: false,
    },
  ];

  return (
    <HeaderSidebarLayout>
      <Breadcrumbs labels={["Compras"]} />
      <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
        <Box sx={{ minHeight: 600, width: "100%", p: 2 }}>
          <DataGrid
            columns={tableColumns}
            rows={purchases}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            autoHeight={true}
            rowSelection={false}
            loading={isLoading}
          />
        </Box>
      </Paper>
    </HeaderSidebarLayout>
  );
};

export default PurchasesView;
