import { Axios } from "axios";
import { ISignIn } from "../../types/AuthenticationTypes";

const API = {
  signIn: {
    url: `/auth/signin`,
    method: "POST",
  },
};

export class AuthService {
  constructor(private axios: Axios) {}

  async signIn(data: ISignIn) {
    const response = this.axios.request({
      url: API.signIn.url,
      method: API.signIn.method,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
    return response;
  }
}

// import AsyncStorage from '@react-native-async-storage/async-storage';
// import api from './api'; // Importa a configuração do Axios

// export const login = async (username: string, password: string) => {
//   try {
//     const response = await api.post('/login', { username, password });
//     const { token } = response.data;
//     await AsyncStorage.setItem('userToken', token);
//     return token;
//   } catch (error) {
//     console.error('Login error', error);
//     throw error;
//   }
// };

// export const logout = async () => {
//   await AsyncStorage.removeItem('userToken');
// };
