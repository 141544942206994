import { Box, CircularProgress, Paper, Typography } from "@mui/material";

interface TotalCardProps {
  title: string;
  subtitle?: string;
  value: string;
  loading: boolean;
}

const TotalCard: React.FC<TotalCardProps> = ({
  title,
  subtitle,
  value,
  loading,
}) => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 300,
      }}
    >
      {loading && (
        <Box
          sx={{
            flex: 1,
            flexDirection: "column",
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <>
          <Box
            sx={{
              pt: 2,
              pl: 4,
              flexDirection: "column",
              textAlign: "start",
            }}
          >
            <Typography
              variant="body1"
              color="primary"
              sx={{ fontSize: "1rem", fontWeight: 800 }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant="body2"
                color="secondary"
                sx={{ fontSize: "0.75rem", fontWeight: 600 }}
              >
                {subtitle}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              flex: 1,
              flexDirection: "column",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              color={"black"}
              sx={{ fontSize: "2rem", fontWeight: 800 }}
            >
              {value}
            </Typography>
          </Box>
        </>
      )}
    </Paper>
  );
};

export default TotalCard;
