export const COMPANY_SEGMENTS = [
  { value: "1", label: "Alimentação e Bebidas" },
  { value: "2", label: "Artes" },
  { value: "3", label: "Artesanato" },
  { value: "4", label: "Artigos Esportivos" },
  { value: "5", label: "Assistência Técnica" },
  { value: "6", label: "Beleza e Estética" },
  { value: "7", label: "Brinquedos e Presentes" },
  { value: "8", label: "Educação" },
  { value: "9", label: "Entretenimento" },
  { value: "10", label: "Farmácia" },
  { value: "11", label: "Floricultura" },
  { value: "12", label: "Horti Fruti" },
  { value: "13", label: "Informática" },
  { value: "14", label: "Lazer" },
  { value: "15", label: "Livraria" },
  { value: "16", label: "Manutenção e Reparos" },
  { value: "17", label: "Mercearia e Rotisserie" },
  { value: "18", label: "Moda/Vestuário/Acessórios" },
  { value: "19", label: "Móveis e Decoração" },
  { value: "20", label: "Papelaria" },
  { value: "21", label: "Perfumaria" },
  { value: "22", label: "Petshop" },
  { value: "23", label: "Reforma e Construção" },
  { value: "24", label: "Saúde e Bem-estar" },
  { value: "25", label: "Serviços Automotivos" },
  { value: "26", label: "Serviços Financeiros e Corretagem" },
  { value: "27", label: "Serviços Gráficos" },
  { value: "28", label: "Serviços Pessoais" },
  { value: "29", label: "Serviços Pets" },
  { value: "30", label: "Supermercado" },
  { value: "31", label: "Suplementação" },
  { value: "32", label: "Suvenires" },
  { value: "33", label: "Técnicos e Profissionais" },
  { value: "34", label: "Transporte" },
  { value: "35", label: "Turismo e Hospedagem" },
  { value: "36", label: "Outros Produtos" },
  { value: "37", label: "Outros Serviços" },
];
