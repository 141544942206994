import { Container, Grid } from "@mui/material";
import * as React from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import PurchaseAverageCard from "../components/Dashboard/cards/PurchaseAverageCard/PurchaseAverageCard";
import PurchaseTotalCard from "../components/Dashboard/cards/PurchaseTotalCard/PurchaseTotalCard";
import TaxTotalCard from "../components/Dashboard/cards/TaxTotalCard/TaxTotalCard";
import PurchasesQuantityColumnChart from "../components/Dashboard/charts/column/PurchasesQuantityColumnChart/PurchasesQuantityColumnChart";
import PurchasesValueColumnChart from "../components/Dashboard/charts/column/PurchasesValueColumnChart/PurchasesValueColumnChart";
import VouchersRedeemedColumnChart from "../components/Dashboard/charts/column/VouchersRedeemedColumnChart/VouchersRedeemedColumnChart";
import VouchersUsedColumnChart from "../components/Dashboard/charts/column/VouchersUsedColumnChart/VouchersUsedColumnChart";
import HeaderSidebarLayout from "../components/Layout/HeaderSidebarLayout";

const DashboardView: React.FC = () => {
  const renderTotalCards = () => {
    return (
      <>
        <Grid item xs={12} md={6} lg={4}>
          <PurchaseTotalCard
            key={"tc-purchaseTotal"}
            title={"Total de Compras"}
            subtitle={"Últimos 7 dias"}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <PurchaseAverageCard
            key={"tc-purchaseAverage"}
            title={"Média de compras"}
            subtitle={"Últimos 7 dias"}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <TaxTotalCard
            key={"tc-cashbackGenerated"}
            title={"Taxa VDC"}
            subtitle={"Últimos 7 dias"}
          />
        </Grid>
      </>
    );
  };

  const renderColumnCharts = () => {
    return (
      <>
        <Grid item xs={12} md={6} lg={6}>
          <VouchersRedeemedColumnChart
            key={"cchart-vouchersRedeemed"}
            title="Vouchers Resgatados"
            subtitle={"Últimos 7 dias"}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <VouchersUsedColumnChart
            key={"cchart-vouchersUsed"}
            title="Vouchers Utilizados"
            subtitle={"Últimos 7 dias"}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <PurchasesQuantityColumnChart
            key={"cchart-purchasesQuantity"}
            title="Compras (qte)"
            subtitle={"Últimos 7 dias"}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <PurchasesValueColumnChart
            key={"cchart-purchasesValue"}
            title="Compras (valor)"
            subtitle={"Últimos 7 dias"}
          />
        </Grid>
      </>
    );
  };

  return (
    <HeaderSidebarLayout>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Breadcrumbs labels={["Dashboard"]} />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={2}>
            {renderTotalCards()}
            {renderColumnCharts()}
          </Grid>
        </Container>
      </Container>
    </HeaderSidebarLayout>
  );
};

export default DashboardView;
