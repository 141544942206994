import { Box, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import HeaderSidebarLayout from "../../../components/Layout/HeaderSidebarLayout";
import { useAuth } from "../../../contexts/AuthContext";
import { services } from "../../../services/services";
import { FormatNumberToBRL } from "../../../services/utils/numberFormater";
import {
  IVoucherCredit,
  IVoucherCreditListResponse,
} from "../../../types/VoucherCreditTypes";

const VoucherCreditsView: React.FC = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      loadData();
    }
  });

  const [isLoading, setLoading] = useState(false);
  const { userId, userToken } = useAuth();
  const [voucherCredits, setVoucherCredits] = useState<IVoucherCredit[]>([]);
  const loadData = async () => {
    try {
      setLoading(true);
      await services.voucherService
        .listVouchersCredits(userId, userToken)
        .then((response) => {
          if (response.status === 200) {
            const voucherListResponse: IVoucherCreditListResponse =
              response?.data;
            setVoucherCredits(voucherListResponse.credits);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const tableColumns: GridColDef<(typeof voucherCredits)[number]>[] = [
    { field: "id", headerName: "#ID", width: 50, type: "number" },
    {
      field: "nmCustomer",
      headerName: "Cliente",
      width: 100,
      type: "string",
    },
    {
      field: "nuCpf",
      headerName: "CPF",
      width: 100,
      type: "string",
    },
    {
      field: "nmCompany",
      headerName: "Empresa",
      width: 100,
      type: "string",
    },
    {
      field: "nuCnpj",
      headerName: "CNPJ",
      width: 100,
      type: "string",
    },
    {
      field: "dsVoucher",
      headerName: "Voucher",
      width: 100,
      type: "string",
    },
    {
      field: "vlDiscount",
      headerName: "Cashback",
      width: 100,
      valueGetter: (value, row) =>
        `${value == null ? "" : value}${value == null ? "" : "%"}`,
    },
    {
      field: "dtAvailable",
      headerName: "Liberado em",
      width: 100,
      type: "string",
    },
    {
      field: "dtRedeemed",
      headerName: "Resgatado em",
      width: 100,
      type: "string",
    },
    {
      field: "dtUsed",
      headerName: "Utilizado em",
      width: 100,
      type: "string",
    },
    {
      field: "dtAccepted",
      headerName: "Aceito em",
      width: 100,
      type: "string",
    },
    {
      field: "dtExpire",
      headerName: "Expirado em",
      width: 100,
      type: "string",
    },
    {
      field: "vlPurchaseCustomer",
      headerName: "Valor da Compra",
      width: 100,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },
    {
      field: "vlPaidCashback",
      headerName: "Valor em Cashback",
      width: 100,
      valueGetter: (value, row) =>
        value == null ? "" : FormatNumberToBRL(value),
    },
    {
      field: "flRedeemed",
      headerName: "Resgatado?",
      width: 100,
      type: "boolean",
    },
    {
      field: "flUsed",
      headerName: "Utilizado?",
      width: 100,
      type: "boolean",
    },
    {
      field: "flExpired",
      headerName: "Expirado?",
      width: 100,
      type: "boolean",
    },
  ];

  return (
    <HeaderSidebarLayout>
      <Breadcrumbs labels={["Vouchers", "Aprovações"]} />
      <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
        <Box sx={{ minHeight: 600, width: "100%", p: 2 }}>
          <DataGrid
            columns={tableColumns}
            rows={voucherCredits}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            autoHeight={true}
            rowSelection={false}
            loading={isLoading}
          />
        </Box>
      </Paper>
    </HeaderSidebarLayout>
  );
};

export default VoucherCreditsView;
