import { Axios } from "axios";
import { appendAuthenticationHeader } from "../RestClientService";

const API = {
  listCustomerTransactions: {
    url: () => {
      return `/transactions/customer/list`;
    },
    method: "GET",
  },
  listCompanyTransactions: {
    url: () => {
      return `/transactions/company/list`;
    },
    method: "GET",
  },
};

export class TransactionService {
  constructor(private axios: Axios) {}

  async listCustomerTransactions(authToken: string, jwtToken: string) {
    const response = this.axios.request({
      url: API.listCustomerTransactions.url(),
      method: API.listCustomerTransactions.method,
      headers: appendAuthenticationHeader(
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        authToken
      ),
    });
    return response;
  }

  async listCompanyTransactions(authToken: string, jwtToken: string) {
    const response = this.axios.request({
      url: API.listCompanyTransactions.url(),
      method: API.listCompanyTransactions.method,
      headers: appendAuthenticationHeader(
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        authToken
      ),
    });
    return response;
  }
}
