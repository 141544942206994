import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  Slide,
  SlideProps,
  Snackbar,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { blue, green } from "@mui/material/colors";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoVDC from "../../assets/images/vdc-logo.png";
import { useAuth } from "../../contexts/AuthContext";
import { services } from "../../services/services";
import { ISignInResponse } from "../../types/AuthenticationTypes";

const LoginView: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const { storeAuthResponse } = useAuth();

  function Copyright(props: any) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://www.viladocashback.com.br">
          {"Vila do Cashback"}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: green[600],
      },
      secondary: {
        main: blue[600],
      },
    },
  });

  const handleSubmit = async () => {
    try {
      await services.authService
        .signIn({ username, password })
        .then((response) => {
          if (response.status === 200) {
            const signInResponse: ISignInResponse = response?.data;
            if (signInResponse.success) {
              storeAuthResponse(signInResponse.id, signInResponse.token);
              navigate("/dashboard");
            } else {
              setShowAlert(true);
              setAlertMessage(
                signInResponse?.message || "Erro: Tente novamente"
              );
            }
          } else {
            setShowAlert(true);
            setAlertMessage(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="down" />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Snackbar
          open={showAlert}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionComponent={SlideTransition}
          onClose={() => {
            setShowAlert(false);
            setAlertMessage("");
          }}
        >
          <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <CssBaseline />

        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            // display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <img
              src={LogoVDC}
              alt="Vila do Cashback"
              aria-label="Vila do Cashback"
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                type="text"
                id="username"
                name="username"
                label="Usuário"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
                required
                fullWidth
                autoFocus
              />
              <TextField
                type="password"
                id="password"
                name="password"
                label="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                required
                fullWidth
                autoComplete="current-password"
              />

              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Lembrar"
              /> */}

              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
              >
                Login
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    {"Esqueci minha senha"}
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Não tem uma conta? Crie uma aqui"}
                  </Link>
                </Grid>
              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginView;
